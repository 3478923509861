<template>
  <div
    :class="style.background"
    class="rounded-xl p-4 flex justify-between"
  >
    <div class="rounded-full bg-bb-red-100 h-10 w-10 flex items-center justify-center flex-shrink-0">
      <ic-bell
        :class="style.icon"
        class="align-top"
      />
    </div>
    <div class="flex-grow mx-4">
      <slot name="header" />
      <slot name="content" />
    </div>
    <div class="relative">
      <div>
        <div class="flex justify-end">
          <ic-cross @close="$emit('resolve')" />
        </div>
        <div class="absolute bottom-0 right-0">
          <slot name="date">
            <p>{{ currentDate }}</p>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcBell from '@/components/icon/ic-bell.vue'
import IcCross from '@/components/icon/ic-cross.vue'

export default {
  name: 'BannerCard',
  components: { IcCross, IcBell },
  props: {
    type: {
      type: String,
      required: false,
      default: 'success',
    },
  },
  computed: {
    style() {
      switch (this.type) {
        case 'success':
          return { background: 'bg-bb-green-50' }
        case 'alert':
          return { background: 'bg-bb-red-50', icon: 'text-bb-error' }
        default:
          return { background: 'bg-neutral-50' }
      }
    },
    currentDate() {
      return new Date().toISOString().slice(0, 10)
    },
  },
}
</script>
