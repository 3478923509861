<template>
  <div class="bg-neutral-50">
    <div
      v-if="isLoading"
      class="p-10 bg-neutral-50"
    >
      <brightbid-loader
        class="mt-64"
        size="100"
      />
    </div>
    <div
      v-else
      class="main-container base-scrollbar"
    >
      <div class="mb-20 w-full">
        <div class="px-6 pt-6">
          <div class="bg-white py-6 px-4 rounded-lg border-neutral-100 border md:flex justify-between">
            <div class="md:inline-flex gap-3 items-center pt-1">
              <div class="text-gray-700 text-sm font-medium">Show:</div>
              <div class="text-center">
                <div class="min-w-xxs p-1 bg-neutral-50 rounded-full my-auto justify-start items-center inline-flex">
                  <!-- Sites Checkbox -->
                  <div
                    :class="{
                      'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
                      'text-bb-text-secondary font-bold ': portfolioViewMode !== 'site',
                      'text-bb-brand-purple bg-white': portfolioViewMode == 'site',
                    }"
                    @click="togglePortfolioViewMode('site')"
                  >
                    <div class="p2 font-bold">Accounts</div>
                  </div>
                  <!-- Organizations Checkbox -->
                  <div
                    :class="{
                      'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
                      'text-bb-text-secondary ': portfolioViewMode !== 'organization',
                      'text-bb-brand-purple  bg-white': portfolioViewMode == 'organization',
                    }"
                    @click="togglePortfolioViewMode('organization')"
                  >
                    <div class="p2 font-bold">Organizations</div>
                  </div>
                </div>
              </div>
            </div>
            <text-input
              v-if="portfolioViewMode === 'organization'"
              v-model="searchQuery"
              has-icon
              :disabled="isLoading"
              input-name="search-query"
              input-id="search-query"
              input-type="text"
              placeholder="Search organization..."
              padding-left="pl-10"
              class="max-w-64 w-full ml-auto"
            >
              <template #prefix>
                <div class="absolute z-10 h-10 p-2 text-bb-disabled-gray">
                  <IcSearch />
                </div>
              </template>
            </text-input>
          </div>
          <div class="mt-3">
            <div v-if="portfolioViewMode === 'organization'">
              <p class="h4">All Organizations</p>
              <div
                v-for="item in filteredPortfolioData"
                :key="item.id"
                class="cursor-pointer"
              >
                <PortfolioCard
                  keep-alive
                  :organization="item"
                />
              </div>
              <div v-if="filteredPortfolioData.length == 0">No organizations found</div>
            </div>
            <div v-else>
              <p class="h4">All Accounts</p>
              <div
                v-for="site in alphabeticalSitesData"
                :key="site.id"
              >
                <bbSiteCard :site="site" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bbSiteCard from '@/components/card/bbSiteCard.vue'
import PortfolioCard from '@/components/card/PortfolioCard.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextInput from '@/components/input/brightbid/TextInput'
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import IcSearch from 'vue-material-design-icons/Magnify'
import dayjs from 'dayjs'

export default {
  name: 'PortfolioOverview',
  components: {
    TextInput,
    PortfolioCard,
    BrightbidLoader,
    IcSearch,
    bbSiteCard,
  },
  data() {
    return {
      isLoading: false,
      isModalLoading: false,
      formValues: null,
      size: null,
      existingUser: null,
      searchQuery: '',
      today: dayjs(),
      startDate: null,
      endDate: null,
      compareStartDate: null,
      compareEndDate: null,
    }
  },
  computed: {
    ...mapState('portfolio', ['portfolioViewMode']),
    ...mapState('auth', ['user']),
    ...mapState('organization', ['userOrganization']),
    ...mapGetters({
      portfolioData: 'portfolio/portfolioData',
      siteMetrics: 'portfolio/siteMetrics',
      organizationSiteRows: 'site/organizationSiteRows',
    }),
    filteredPortfolioData() {
      let data = this.portfolioData.organizationsData || []
      if (this.searchQuery) {
        data = data.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      }
      return data.slice().sort((a, b) => {
        if (a.starred === b.starred) {
          return a.name.localeCompare(b.name)
        }
        return a.starred ? -1 : 1
      })
    },
    sitesData() {
      return this.portfolioData.sitesData || []
    },
    alphabeticalSitesData() {
      return this.sitesData.slice().sort((a, b) => {
        if (a.starred === b.starred) {
          return a.name.localeCompare(b.name)
        }
        return a.starred ? -1 : 1
      })
    },
  },
  async mounted() {
    this.today = dayjs()
    this.startDate = this.today.subtract(31, 'day').format('YYYY-MM-DD')
    this.endDate = this.today.subtract(1, 'day').format('YYYY-MM-DD')
    this.compareStartDate = this.today.subtract(62, 'day').format('YYYY-MM-DD')
    this.compareEndDate = this.today.subtract(32, 'day').format('YYYY-MM-DD')
    await this.loadPortfolio()
  },
  methods: {
    ...mapActions({
      loadPortfolioData: 'portfolio/loadPortfolioData',
      updateDates: 'portfolio/updateDates',
    }),
    ...mapMutations('portfolio', ['SET_VIEW_MODE']),
    async loadPortfolio() {
      this.isLoading = true
      // Untill we have a date picker, we will use the last 30 days compared to the 30 days before that as default
      if (!this.startDate || !this.endDate || !this.compareStartDate || !this.compareEndDate) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Enter a valid date range.',
            type: 'error',
          },
        })
        this.isLoading = false
        return
      }

      try {
        await this.updateDates({
          startDate: this.startDate,
          endDate: this.endDate,
          compareStartDate: this.compareStartDate,
          compareEndDate: this.compareEndDate,
        })

        await this.loadPortfolioData({
          startDate: this.startDate,
          endDate: this.endDate,
          compareStartDate: this.compareStartDate,
          compareEndDate: this.compareEndDate,
          organizations: this.userOrganization,
        })
        this.$emit(
          'setNotificationsCount',
          this.portfolioData.organizationsData.reduce((sum, item) => sum + item.notification_count, 0),
        )
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch portfolio.',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    togglePortfolioViewMode(mode) {
      this.SET_VIEW_MODE(mode)
    },
  },
}
</script>
<style scoped lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 260px);
}
</style>
