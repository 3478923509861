<template>
  <page class="overflow-y-hidden">
    <page-section
      full-width
      bottom-border
      class="relative"
    >
      <div class="flex justify-between items-end">
        <page-title>
          <template #icon>
            <ic-bars :size="32" />
          </template>
          Portfolio
        </page-title>
      </div>
    </page-section>
    <div class="main-container base-scrollbar bg-white">
      <page-tabs
        class="mt-4"
        :selected-tab="selectedTab"
        :tabs="tabs"
        @select-tab="
          tab => {
            selectedTab = tab
          }
        "
      >
        <template
          v-if="notificationCount"
          #badge
        >
          <span class="bg-bb-background-purple-0 px-2 py-1 rounded-full text-bb-brand-purple">{{
            notificationCount
          }}</span>
        </template>
      </page-tabs>

      <div>
        <component
          :is="selectedTab"
          @setNotificationsCount="setNotificationsCount"
        />
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageTabs from '@/components/shared/PageTabs'
import PageSection from '@/components/base/page/PageSection.vue'
import IcBars from '@/components/icon/ic-bars.vue'
import PortfolioOverviewTab from '@/views/portfolio/PortfolioOverviewTab'
import PortfolioNotificationsTab from '@/views/portfolio/PortfolioNotificationsTab.vue'

export default {
  name: 'PortfolioView',
  components: {
    IcBars,
    Page,
    PageSection,
    PageTabs,
    PortfolioOverviewTab,
    PortfolioNotificationsTab,
  },
  data() {
    return {
      tabs: [
        {
          label: 'Overview',
          value: 'portfolio-overview-tab',
        },
        {
          label: 'Notifications',
          value: 'portfolio-notifications-tab',
          displayBadge: true,
        },
      ],
      selectedTab: 'portfolio-overview-tab',
      notificationCount: 0,
    }
  },
  mounted() {
    this.selectedTab = this.$route.query.tab || 'portfolio-overview-tab'
  },
  methods: {
    setNotificationsCount(count) {
      this.notificationCount = count
    },
  },
}
</script>
